.entry-content {
  position: relative;
  margin: 0 auto;
  text-align: justify;
}

.entry-content strong {
  font-weight: bold;
}

.entry-content strong > * {
  font-weight: bold;
}

@media (max-width: 768px) {
  .entry-content .content-wrapper {
    padding: 0 24px;
  }
}

.entry-content body {
  background: #fff;
}
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.entry-content h2 {
  font-size: 30px;
}
@media (max-width: 768px) {
  .entry-content h2 {
    font-size: 24px;
  }
}
.entry-content h3 {
  font-size: 24px;
}
@media (max-width: 768px) {
  .entry-content h3 {
    font-size: 20px;
  }
}
.entry-content h4 {
  font-size: 20px;
}
.entry-content h5 {
  font-size: 18px;
}
.entry-content > * {
  margin-left: auto;
  margin-right: auto;
}
.entry-content p {
  color: #bdc4c8;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 20px;
  word-break: break-all;
}

@media (max-width: 768px) {
  .entry-content p {
    font-size: 16px;
    line-height: 28px;
  }
}

@media screen and(min-width: 768px) {
  .entry-content p {
    font-size: 28px;
    line-height: 42px;
  }
}

@media screen and(min-width: 992px) {
  .entry-content p {
    font-size: 28px;
    line-height: 48px;
  }
}

.entry-content blockquote,
.entry-content ul,
.entry-content ol {
  margin-left: auto;
  margin-right: auto;
}
.entry-content blockquote {
  padding-left: 30px;
  margin-bottom: 20px;
  border-left: 5px solid #1266f1;
}

.entry-content a {
  border-bottom: 1px solid #1266f1;
}
.entry-content a:hover {
  color: #1266f1;
}
.entry-content em {
  font-style: italic;
}
.entry-content ul,
.entry-content ol {
  font-size: 20px;
  line-height: 32px;
  padding-left: 1em;
  font-family: Gerogia, serif;
}
.entry-content ul li,
.entry-content ol li {
  margin-bottom: 20px;
}
.entry-content ul {
  list-style: disc;
}
.entry-content ol {
  list-style: decimal;
}
.entry-content iframe {
  max-width: 100%;
}
.entry-content .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.entry-content .embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.entry-content .wp-caption {
  max-width: 100%;
}
.entry-content img {
  max-width: 100%;
  height: auto;
}
.entry-content img.aligncenter {
  display: block;
  margin: 0 auto;
}
.entry-content figure {
  max-width: 100%;
  margin: 0;
  margin-bottom: 20px;
}
.entry-content figure.aligncenter {
  margin: 0 auto;
  margin-bottom: 20px;
}
.entry-content figure.aligncenter img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.entry-content figure.alignleft {
  float: left;
  margin-right: 24px;
}
.entry-content figure.alignright {
  float: right;
  margin-left: 24px;
}
.entry-content figure figcaption {
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  margin-left: auto;
  margin-right: auto;
  color: inherit;
  border-bottom: 1px solid #e8e8e8;
}
.entry-content figure figcaption em {
  font-size: 12px;
  color: inherit;
}
.entry-content .card {
  position: absolute;
  width: 160px;
  top: 50%;
  right: 0;
  margin-top: -320px;
  padding-left: 10px;
  border-left: 1px solid #e8e8e8;
}
@media (max-width: 1279px) {
  .entry-content .card {
    display: none;
  }
}
.entry-content .card h2 {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 20px;
}
.entry-content .card ul {
  list-style: none;
  padding: 0;
}
.entry-content .card ul li {
  position: relative;
}
.entry-content .card ul li:nth-child(4),
.entry-content .card ul li:nth-child(5) {
  display: none;
}
.entry-content .card ul li:nth-child(3) {
  margin-bottom: 0;
}
.entry-content .card ul li .post-thumbnail {
  position: relative;
}
.entry-content .card ul li h3 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: inherit;
}
.entry-content .card ul li a.cover-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -2000em;
  border-bottom: none;
}
.entry-content .card ul li:hover .post-thumbnail::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.entry-content .card ul li:hover h3 {
  text-decoration: underline;
}


.rich-text p {
  margin-bottom: 20px;
  word-break: break-all;
}