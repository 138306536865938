.chakra-ui-light {
  overflow: inherit !important;
}

nextjs-portal {
  display: none !important;
}
html {
  font-size: 10px;
}

/* for gray website */
/* html {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
} */

@media (min-width: 640px) {
  html {
    font-size: 16px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'PingFang SC', 'Noto Sans SC', sans-serif;
  background-image: url('https://static.letschuhai.com/static/images/background-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #314475;
}

body[data-page='about-us'] {
  background-image: url('https://static.letschuhai.com/static/images/about-us/body.jpg');
}

* {
  font-family: 'PingFang SC', 'Noto Sans SC', sans-serif;
  font-weight: 400;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}
a {
  pointer-events: inherit !important;
  outline: none;
}
button[disabled] {
  pointer-events: none !important;
  cursor: inherit !important;
  opacity: inherit !important;
  box-shadow: inherit !important;
}

.keen-slider {
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.keen-slider__slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.keen-slider[data-keen-slider-v] {
  flex-wrap: wrap;
}
.keen-slider[data-keen-slider-v] .keen-slider__slide {
  width: 100%;
}
.keen-slider[data-keen-slider-moves] * {
  pointer-events: none;
}

.headerHeadroomMobile {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
}

.headroom {
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
}
.headroom--unfixed {
  position: fixed;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 150ms ease-in;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #66fcf1;

  position: fixed;
  z-index: 1600;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1600;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  display: none;
  width: 24px;
  height: 24px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #1067f0;
  border-left-color: #1067f0;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

@media screen and (min-width: 992px) {
  #nprogress .spinner-icon {
    display: initial;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sticky {
  z-index: 1100;
}

.main-nav {
  background: transparent !important;
}

.sticky .main-nav {
  background: #212832 !important;
}

.main-nav-stick-menu {
  display: none;
}

.main-nav-border {
  display: none;
}

.sticky .main-nav-border {
  display: block;
}

.sticky .main-nav-stick-menu {
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: rgba(255, 255, 255, 1) !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #66fcf1;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.nav-highlight {
  --borderWidth: 2px;
  /* background: #212832; */
  background: transparent;
  color: #fff !important;
  position: relative;
  border-radius: 12px;
  display: block;
  padding-inline: 1.5rem;
  font-size: 13px;
  font-weight: bold;
  line-height: 24px;
  transition: all 0.3s ease;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(45deg, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    border-radius: 14px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  &:hover {
    background: transparent;
    text-decoration: none !important;
    color: #fff;
  }
}

.aside-widget {
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  position: relative;

  > * {
    position: relative;
    transition: all 0.3s ease;
  }

  .aside-widget__default {
    width: 44px;
    height: 44px;
    background: var(--chakra-colors-gray-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--chakra-colors-gray-800);
    border: 1px solid var(--chakra-colors-gray-300);
  }
  .aside-widget__active {
    display: none;
    width: 44px;
    height: 44px;
    padding: 0 5px;
    line-height: 22px;
    color: var(--chakra-colors-gray-800);
    background-color: var(--chakra-colors-primary);
    border: 1px solid darken(var(--chakra-colors-gray-300), 10%);
  }

  .aside-widget__icon {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--chakra-colors-gray-800);
  }
  .aside-widget__link {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &:hover {
    .aside-widget__default {
      display: none;
    }
    .aside-widget__active {
      display: block;
    }
  }
}
